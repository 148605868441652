<template>
  <layout page="news-list">
    <page-title icon="lni lni-information" :breadcrumb="breadcrumb">Noticias</page-title>
    <div class="card table">
      <header class="card-header">
        <p class="card-header-title">
          <span>Noticias</span>
        </p>
        <div class="card-header-icon" aria-label="novo aluno">
          <router-link class="button is-primary" to="/admin/noticias/nova">
            <i class="lni lni-plus mr-1"></i> <span>Nova Noticia</span>
          </router-link>
        </div>
      </header>
      <div class="card-content">
        <div v-if="news.length > 0" class="table-container">
          <table class="table is-fullwidth">
            <thead>
              <tr>
                <th>Titulo</th>
                <th>Autor</th>
                <th>Publicação</th>
                <td>Ações</td>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(notice) in news"
                :key="notice.id"
              >
                <td>{{ notice.title }}</td>
                <td>{{ notice.author }}</td>
                <td>{{ notice.createdAt | dateBr }}</td>
                <td>
                  <router-link class="button is-primary is-small mr-1" :to="`/admin/noticias/${notice.id}/editar`" title="Editar Noticia">
                    <i class="lni lni-pencil-alt"></i>
                  </router-link>
                  <button class="button is-danger is-small" title="Excluir Noticia" @click="excluir(index, notice)">
                    <i class="lni lni-trash"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <empty-list v-else entity="noticia" />
      </div>
    </div>
  </layout>
</template>

<script>
import api from '@/api'
import { format } from 'date-fns'
import { DASHBOARD, NEWS_LIST } from '@/config/breadcrumb/admin'
import Layout from '@/layout/admin/Layout'
import PageTitle from '@/components/admin/PageTitle'
import EmptyList from '@/components/ui/EmptyList'

export default {
  name: 'NewsList',
  components: {
    Layout,
    PageTitle,
    EmptyList
  },
  created () {
    api.get('/news').then(res => {
      this.news = res.data
    })
  },
  data () {
    return {
      news: []
    }
  },
  computed: {
    breadcrumb () {
      return [
        DASHBOARD,
        { ...NEWS_LIST, isActive: true }
      ]
    }
  },
  filters: {
    dateBr (value) {
      if (!value) return
      return format(new Date(value), 'd/MM/y')
    }
  },
  methods: {
    excluir (index, notice) {
    }
  }
}
</script>
